
import { Vue, Component, Watch } from "vue-property-decorator"
import ConsultantProfile from "../consultant/ConsultantProfile.vue"
import {
  TalentPoolQuery,
  ArchiveApplicationMutation,
  ApproveApplicationMutation,
  ActivatePeraltaAccountMutation,
  ConsultantTalentFragmentFragment,
  ApproveApplicationMutationMutation,
  ArchiveApplicationMutationMutation,
  ActivatePeraltaAccountMutationMutation,
  ShortlistApplicantForAssessmentMutation,
  ShortlistApplicantForAssessmentMutationMutation,
  RejectApplicationMutation,
  RejectApplicationMutationMutation,
} from "@/gql"
import { FetchResult } from "apollo-link"
import AppDetailPage from "@/components/layout/AppDetailPage.vue"
import EditConsultantTalentForm from "@/components/forms/EditConsultantTalentForm.vue"
import BasicInfoField from "@/components/consultant/BasicInfoField.vue"
import DocumentCard from "@/components/content/DocumentCard.vue"
import { CandidateRejectionTypeEnumReasons } from "@/constants"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import { ApolloError, ApolloQueryResult, NetworkStatus } from "@apollo/client/core"
import { SmartQuery } from "vue-apollo/types/vue-apollo"
import SessionHistory from "@/services/SessionHistory"

@Component({
  components: {
    BasicInfoField,
    DocumentCard,
    AppDetailPage,
    ConsultantProfile,
    EditConsultantTalentForm,
    FeedBackMessage,
  },
})
export default class ConsultantDetail extends Vue {
  readonly query = TalentPoolQuery

  consultant: ConsultantTalentFragmentFragment | null = null
  showEditModal = false

  errorLoading = false
  networkError = false

  applicationAction: string | null = null
  actionLoading = false
  showAssessmentLinkModal = false
  assessmentLink: string | null = null

  assessmentLoading = false
  showActivateProfileDialog = false
  showRejectModal = false
  rejectionReasons = CandidateRejectionTypeEnumReasons
  canAdmitToPool = false

  showAdmitToPoolDialog = false
  form = {
    rejectionReason: null,
  }

  onError(error: ApolloError) {
    if (error.graphQLErrors) {
      this.errorLoading = true
    } else if (error.networkError) {
      this.networkError = true
    }
  }

  getStateColor(state: string) {
    switch (state) {
      case "shortlisted":
        return "blue"
      case "rejected":
        return "error"
      case "pending":
        return "amber"
      default:
        return "primary"
    }
  }

  get refetchQueries() {
    return [
      {
        query: this.query,
        variables: {
          filter: {
            idIn: [this.$route.params.id],
          },
          per: 1,
        },
      },
    ]
  }

  get isApplicant(): boolean {
    return ["pending", "shortlisted", "rejected", "archived"].includes(this.consultant?.state || "")
  }

  onResult(result: ApolloQueryResult<any>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.assessmentLoading = false
      this.errorLoading = false

      if (result.data && result.data.talentPool && result.data.talentPool.data.length) {
        this.consultant = result.data.talentPool.data[0]
      }
    } else if (result.networkStatus === NetworkStatus.error) {
      this.errorLoading = true
    }
  }

  onApplicationActionComplete() {
    this.actionLoading = false
  }

  async shortlistApplication(id: number) {
    const result: FetchResult<ShortlistApplicantForAssessmentMutationMutation> = await this.mutate({
      mutation: ShortlistApplicantForAssessmentMutation,
      variables: {
        id,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.onApplicationActionComplete()
      },
    })

    if (result.data?.shortlistApplicant) {
      this.addSuccess("Application shortlisted successfully!")
      this.applicationAction = null
    }
  }

  async approveApplication(id: number) {
    const result: FetchResult<ApproveApplicationMutationMutation> = await this.mutate({
      mutation: ApproveApplicationMutation,
      variables: {
        id,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.onApplicationActionComplete()
      },
    })

    if (result?.data?.approveApplication) {
      this.addSuccess("Application approved successfully!")
      this.applicationAction = null
      this.showAdmitToPoolDialog = false
    }
  }

  async archiveApplication(id: number) {
    const result: FetchResult<ArchiveApplicationMutationMutation> = await this.mutate({
      mutation: ArchiveApplicationMutation,
      variables: {
        id,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.onApplicationActionComplete()
      },
    })

    if (result.data && !result.data.archiveApplication.error) {
      this.addSuccess("Application archived successfully!")
      this.applicationAction = null
    }
  }

  async rejectApplication() {
    this.actionLoading = true

    const result: FetchResult<RejectApplicationMutationMutation> = await this.mutate({
      mutation: RejectApplicationMutation,
      variables: {
        id: this.consultant?.id,
        rejectionReason: this.form.rejectionReason,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.actionLoading = false
      },
    })

    if (result?.data?.rejectApplication) {
      this.addSuccess("Application rejected successfully!")
      this.showRejectModal = false
    }
  }

  async onApplicationAction(action: string) {
    const id = parseInt(this.$route.params.id)
    this.actionLoading = true

    switch (action) {
      case "shortlist":
        this.shortlistApplication(id)
        break

      case "archive":
        this.archiveApplication(id)
        break

      default:
        this.addError(`Unknown action ${action}`)
        break
    }
  }

  async activatePeraltaProfile() {
    this.actionLoading = true

    const result: FetchResult<ActivatePeraltaAccountMutationMutation> = await this.mutate({
      mutation: ActivatePeraltaAccountMutation,
      variables: {
        id: this.consultant?.id,
      },
      refetchQueries: this.refetchQueries,
      done: () => {
        this.actionLoading = false
      },
    })

    if (result?.data?.activatePeraltaAccount) {
      this.addSuccess("Consultant invited to Peralta successfully")
      this.showActivateProfileDialog = false
    }
  }

  async handleLoadingErrorButtonClick(query: SmartQuery<any>) {
    this.$set(this, "assessmentLoading", true)
    await query.refetch()
  }

  @Watch("canAdmitToPool")
  onCanAdmitToPoolChange() {
    this.consultant &&
      this.consultant.state !== "approved" &&
      this.consultant.state !== "rejected" &&
      this.approveApplication(this.consultant.id)
  }

  @Watch("consultant")
  onConsultantChange() {
    if (this.consultant) {
      const sessionHistory = new SessionHistory()
      sessionHistory.changeRoute(this.$route.name!, { text: this.consultant.name! })
    }
  }
}
