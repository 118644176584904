var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppDetailPage',{attrs:{"title":_vm.consultant ? _vm.consultant.name : ''},scopedSlots:_vm._u([{key:"tools",fn:function(){return [_vm._t("tools")]},proxy:true}],null,true)},[_c('section',[_c('ApolloQuery',{class:{ 'parent-div-loading-error': _vm.errorLoading },attrs:{"query":_vm.query,"variables":{
        filter: {
          idIn: [_vm.$route.params.id],
        },
        per: 1,
      },"notifyOnNetworkStatusChange":""},on:{"result":_vm.onResult,"error":_vm.onError},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading }, query, isLoading }){return [(loading || isLoading)?_c('div',{staticClass:"pa-5"},_vm._l((5),function(n){return _c('v-skeleton-loader',{key:`consulant_detail_loader_${n}`,staticClass:"mb-3",attrs:{"type":"card"}})}),1):(_vm.consultant)?_c('section',[(!['applying'].includes(_vm.consultant.state))?_c('ConsultantProfile',{attrs:{"consultant":_vm.consultant,"backRoute":_vm.isApplicant ? _vm.$routes.TalentApplicants : _vm.$routes.TalentPool,"queries":_vm.refetchQueries},on:{"save":() => query.refetch()},scopedSlots:_vm._u([{key:"fields-append",fn:function(){return [_c('v-divider',{staticClass:"mt-5"}),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),(_vm.can('talent_team'))?_c('TextButton',{staticClass:"ma-0",on:{"click":function($event){_vm.showEditModal = true}}},[_vm._v("Edit")]):_vm._e()],1),_c('v-row',{staticClass:"talent-info-wrap"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('BasicInfoField',{attrs:{"label":"Year Joined","text":_vm.consultant.yearJoined}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('BasicInfoField',{attrs:{"label":"Field","text":_vm.consultant.field}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('BasicInfoField',{attrs:{"label":"Status","text":_vm.consultant.status}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('BasicInfoField',{attrs:{"label":"Comments","text":_vm.consultant.comments}})],1)],1)]},proxy:true}],null,true)}):_c('FeedBackMessage',{attrs:{"customMessage":"Sorry, consultant is applying","preset":"notFound"}}),_c('Modal',{attrs:{"title":"Edit Consultant"},model:{value:(_vm.showEditModal),callback:function ($$v) {_vm.showEditModal=$$v},expression:"showEditModal"}},[_c('EditConsultantTalentForm',{attrs:{"consultant":_vm.consultant,"refetchQueries":_vm.refetchQueries},on:{"save":() => {
                  _vm.showEditModal = false
                },"close":function($event){_vm.showEditModal = false}}})],1)],1):(_vm.errorLoading)?_c('FeedBackMessage',{attrs:{"resource":"consultant","preset":"loadingError","buttonOnClick":() => _vm.handleLoadingErrorButtonClick(query)}}):_c('FeedBackMessage',{attrs:{"resource":"Consultant","preset":"notFound"}})]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }